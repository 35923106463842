import React from 'react';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';
import Maker from '../components/shared/Maker';
import GiveFeedback from '../components/shared/GiveFeedback';
import SubscriptionForm from '../components/shared/SubscriptionForm';

const Newsletter = () => (
  <div>
    <Maker />
    <GiveFeedback />
    <SEO
      title="Kuma Learn - Join Our Newsletter and Get Early Access"
      description="Sign up to our newsletter to get updates and early access to resources and and exclusive content. Learn Japanese vocabulary visually, with pictures."
    />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h1 className="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl">
          Stay up-to-date with news and releases.
        </h1>
        <p className="inline text-3xl font-extrabold tracking-tight text-green-600 sm:block sm:text-4xl">
          Sign up to the newsletter!
        </p>
        <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-500">
          By signing up, you will get news, updates when new learning resources become available
          and exclusive content.
        </p>
        <div className="mt-8 sm:flex">
          <SubscriptionForm />
        </div>
      </div>
    </div>
  </div>
);

const NewsletterPage = () => (
  <Container>
    <Newsletter />
  </Container>
);

export default NewsletterPage;
