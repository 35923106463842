import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import mario from '../../images/mario.png';

const MadeBy = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  border-top-left-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 172px;
  height: 36px;
  background-color: #efefef;
  border: 1px solid #E4E7EB;
  box-shadow: rgba(0, 0, 0, 0.2) -1px -1px 8px;
`;

const MadeByImg = styled.img`
  border-radius: 12px;
`;

const MadeByText = styled.span`
  color: #66788A;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const Maker = () => (
  <MadeBy>
    <Link to="/about">
      <div className="flex items-center h-full">
        <MadeByImg alt="Made by Mario Hayashi" src={mario} width="24px" />
        <div className="ml-2">
          <MadeByText>
            By Mario Hayashi
          </MadeByText>
        </div>
      </div>
    </Link>
  </MadeBy>
);

export default Maker;
